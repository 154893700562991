import axios from 'axios'
import { computed, ComputedRef, defineAsyncComponent, ref } from 'vue'
import { defineStore } from 'pinia'
import { EmitterEvent, useEventEmitter } from '@/shared/utils/event-emitter/useEventEmitter'
import {
  Api3_JwtResponseUserDto,
  Api3_LightRealmWithRolesDto,
  Api3_JwtResponseDto,
  Api3_UserActivityDTO,
} from '@/shared/api/types/api-v3-service-auth'
import { UserAuthority, UserRole } from '@/shared/types/roles'
import { OkPromise } from '@/store/common/base-ui/BaseUiInterface'
import UserStoreInterface from './UserStoreInterface'
import useAuthApi from '@/shared/api/auth/authApi'
import { useBaseUi } from '@/store/common/base-ui/useBaseUi'
import { getAuthorities, UserPermission } from '@/store/auth/user-store/permissions'

const DEFAULT_LANG = 'ru'
type Theme = 'light' | 'dark'

const SettingsDialog = defineAsyncComponent(() => import('@/components/auth/settings-dialog/index.vue'))
const UserProfileDialog = defineAsyncComponent(() => import('@/components/auth/user-profile-dialog/index.vue'))

export const useUserStore = defineStore('user', (): UserStoreInterface => {
  const api = useAuthApi()

  const eventEmitter = useEventEmitter()
  const { openDialog } = useBaseUi()

  // state
  const _logged = ref(false)
  const _realmGuid = ref('')
  const _accessToken = ref('')
  const _refreshToken = ref('')
  const _lang = ref(DEFAULT_LANG)
  const _userData = ref({} as Api3_JwtResponseUserDto)
  const _userActivity = ref([] as Api3_UserActivityDTO[])
  const _debugMode = ref(false)
  const _theme = ref('light' as Theme)
  const userName = ref('')
  let _sessionId = ''

  // getters
  const logged = computed(() => _logged.value)
  const lang = computed(() => _lang.value)
  const theme = computed(() => _theme.value)
  const debugMode = computed(() => _debugMode.value)
  const userData = computed(() => _userData.value)
  const userActivity = computed(() => _userActivity.value)
  const userRealms: ComputedRef<Api3_LightRealmWithRolesDto[]> = computed(() =>
    (_userData.value.realms || []).sort((a, b) => a.presentation_name.localeCompare(b.presentation_name)),
  )
  const _masterRealm: ComputedRef<Api3_LightRealmWithRolesDto | undefined> = computed(() =>
    (_userData.value.realms || []).find(realm => realm.roles.some(r => r.name === 'ROLE_MASTER_ADMIN')),
  )
  const realmGuid = computed(() => {
    const realm = userRealms.value.find(r => r.guid === _realmGuid.value)
    return realm ? realm.guid : ''
  })
  const realmName = computed(() => {
    return userRealms.value.find(r => r.guid === _realmGuid.value)?.name
  })
  const isAdmin = computed(() => !_masterRealm.value && hasRole(UserRole['ROLE_REALM_ADMIN']))
  const isMasterAdmin = computed(() => _userData.value.isMasterAdmin)

  // actions
  function getFirstRealmGuid() {
    if (userRealms.value.length === 0) return ''
    return userRealms.value[0].guid
  }

  function changePassword(oldPassword: string, newPassword: string) {
    return api.changePassword(oldPassword, newPassword)
  }

  function _loadFromStorage() {
    _lang.value = localStorage.getItem('lang') || DEFAULT_LANG
    _theme.value = (localStorage.getItem('theme') as Theme) || 'light'
    _accessToken.value = localStorage.getItem('access_token') || ''
    if (_accessToken.value) {
      _logged.value = true
      _refreshToken.value = localStorage.getItem('refresh_token') || ''
      _realmGuid.value = localStorage.getItem('realmGuid') || ''
      _userData.value = JSON.parse(localStorage.getItem('userData') || '{}')
      _debugMode.value = localStorage.getItem('debug') === 'true'
      _sessionId = getSessionId(_accessToken.value)
      userName.value = getUserName(_accessToken.value)
    }
    _updateApiHeaders()
  }

  function decodeJWT(token: string) {
    const base64Url = token.split('.')[1]
    if (!base64Url) return { jti: '', sub: 'user' }
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(atob(base64))
  }

  function getSessionId(accessToken: string): string {
    const decoded = decodeJWT(accessToken) || {}
    return decoded.jti
  }

  function getUserName(accessToken: string): string {
    const decoded = decodeJWT(accessToken) || {}
    return decoded.sub
  }

  function loginUser(username: string, password: string): Promise<void> {
    return api.loginUser(username, password).then(({ user, refresh_token, access_token }) => {
      console.log(user)
      _logged.value = true
      _userData.value = user
      _accessToken.value = access_token
      _refreshToken.value = refresh_token
      _realmGuid.value = ''
      _sessionId = getSessionId(access_token)
      userName.value = username
      _saveToStorage()
    })
  }

  function clear() {
    _logged.value = false
    _realmGuid.value = ''
    _accessToken.value = ''
    _refreshToken.value = ''
    _sessionId = ''
    _userData.value = {} as Api3_JwtResponseUserDto
    _saveToStorage()
  }

  function logoutUser(): Promise<void> {
    if (!_logged.value) return Promise.resolve()
    changeDomain(undefined, realmGuid.value)
    resetApiHeader()
    return api
      .logoutUser()
      .catch(() => {
        //
      })
      .finally(() => clear())
  }

  function restorePassword(username: string): Promise<void> {
    return api.restorePassword(username)
  }

  function verifyPassword(user_guid: string, new_password: string, verify_code: string): Promise<void> {
    return api.verifyPassword(user_guid, new_password, verify_code)
  }

  function _saveToStorage() {
    localStorage.setItem('realmGuid', isMasterAdmin.value ? getFirstRealmGuid() : _realmGuid.value)
    localStorage.setItem('userData', JSON.stringify(_userData.value))
    localStorage.setItem('access_token', _accessToken.value)
    localStorage.setItem('refresh_token', _refreshToken.value)
    localStorage.setItem('lang', _lang.value)
    localStorage.setItem('theme', _theme.value)
    localStorage.setItem('debug', String(_debugMode.value))

    _updateApiHeaders()
  }

  function resetApiHeader() {
    const headers = (axios as any).defaults.headers.common
    delete headers.Realm_guid
  }

  function _updateApiHeaders() {
    const headers = (axios as any).defaults.headers.common
    if (_logged.value) {
      headers['Authorization'] = `Bearer ${_accessToken.value}`
      headers['Realm_guid'] = realmGuid.value
      headers['Session'] = _sessionId
      headers['Language'] = _lang.value
    } else {
      delete headers.Authorization
      delete headers.Realm_guid
      delete headers.Session
      delete headers.Language
    }
  }

  function updateRealm(guid: string): void {
    changeDomain(guid, realmGuid.value)
    _realmGuid.value = guid
    _saveToStorage()
  }

  function updateSettings(options: { lang: string; theme: Theme }) {
    _lang.value = options.lang
    // _theme.value = options.theme
    _saveToStorage()
  }

  function updateDebugMode(value: boolean) {
    _debugMode.value = value
    _saveToStorage()
  }

  function hasAuthority(authority: UserAuthority | string): boolean {
    if (!_logged.value) return false
    const realm = userRealms.value.find(x => x.guid === realmGuid.value)
    if (!realm) return false
    const result = realm.authorities.includes(authority)
    if (!result) {
      console.log('Отказ в правах, authority: ' + authority)
    }
    return result
  }

  function hasPermission(permission: UserPermission): boolean {
    const result = getAuthorities(permission).every(hasAuthority)
    if (!result) {
      console.log('=== permission: ' + permission)
    }
    return result
  }

  function hasRole(userRole: UserRole) {
    if (!_logged.value || !_realmGuid.value) return false
    const currentRealm = (_userData.value.realms || []).find(realm => realm.guid === _realmGuid.value)
    if (!currentRealm) return false
    return Boolean(currentRealm.roles.find(role => role.name === userRole))
  }

  function _updateTokens({ access_token, refresh_token }: Api3_JwtResponseDto) {
    _accessToken.value = access_token
    _refreshToken.value = refresh_token
    _saveToStorage()
  }

  const mainMenu = computed(() => {
    const items: any[] = []
    if (!isMasterAdmin.value) {
      items.push({ id: 1, link: '/applications', label: $translate.applications, icon: 'apps' })
      items.push({ id: 2, link: '/etl/datasources', label: 'ETL', icon: 'transform' })
    }
    if (isAdmin.value || isMasterAdmin.value) {
      items.push({ id: 7, link: '/security/users', label: $translate.security })
      items.push({
        id: 8,
        link: '/administration/servers',
        label: $translate.administration,
        icon: 'admin_panel_settings',
      })
    }
    return items
  })

  async function loadUserActivity() {
    _userActivity.value = await api.fetchAllUsersActivity()
  }

  function openSettingsDialog(): OkPromise {
    return openDialog({
      component: SettingsDialog,
    })
  }

  function openUserProfileDialog(): OkPromise {
    return openDialog({
      component: UserProfileDialog,
    })
  }

  function init() {
    _loadFromStorage()
    eventEmitter.on(EmitterEvent.TOKENS_UPDATED, _updateTokens)
  }

  function changeDomain(to: string | undefined, from: string | undefined) {
    if (isMasterAdmin.value) return
    if (to === from) return
    if (from !== undefined && from !== '') {
      api.exitFromDomain(from)
    }
    if (to !== undefined && to !== '') {
      api.enterToDomain(to)
    }
  }

  // expose

  return {
    logged,
    userData,
    userRealms,
    userActivity,
    isMasterAdmin,
    isAdmin,
    realmGuid,
    realmName,
    lang,
    theme,
    debugMode,
    userName,
    clear,
    updateRealm,
    updateSettings,
    updateDebugMode,
    restorePassword,
    verifyPassword,
    loginUser,
    logoutUser,
    changePassword,
    hasAuthority,
    hasPermission,
    hasRole,
    mainMenu,
    loadUserActivity,
    openSettingsDialog,
    openUserProfileDialog,
    init,
    changeDomain,
    getFirstRealmGuid,
  }
})
