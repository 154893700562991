import { createApp } from 'vue'
import { Quasar, quasarUserOptions } from './plugin/quasar'
import './styles/index.scss'
import App from './index.vue'
import { store, router, translate } from './providers'

const app = createApp(App)

// @ts-ignore
app.use(Quasar, quasarUserOptions)
app.use(translate(() => callback()))
app.use(router)
app.use(store)

let callback: any = null
function onAppReady(cb: any) {
  callback = cb
}
export { app, onAppReady }
