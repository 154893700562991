export enum EmitterEvent {
  JWT_EXPIRED_OR_INVALID = 'JWT_EXPIRED_OR_INVALID',
  TOKENS_UPDATED = 'TOKENS_UPDATED',
  FORM_ELEMENT_CHANGED = 'FORM_ELEMENT_CHANGED',
}

type EmitFunction = (payload: any) => void

class EventEmitter {
  private subscribers = {} as Record<EmitterEvent, EmitFunction[]>

  public on(event: EmitterEvent, func: EmitFunction) {
    if (!this.subscribers[event]) this.subscribers[event] = []
    this.subscribers[event].push(func)
  }

  public off(event: EmitterEvent, func: EmitFunction) {
    if (!this.subscribers[event]) return
    const index = this.subscribers[event].indexOf(func)
    if (index === -1) return
    this.subscribers[event].splice(index, 1)
  }

  public emit(event: EmitterEvent, payload?: any) {
    if (!this.subscribers[event]) return
    this.subscribers[event].forEach(f => f(payload))
  }
}

export function useEventEmitter(): EventEmitter {
  if ((window as any).eventEmitter) return (window as any).eventEmitter as EventEmitter
  const eventEmitter = new EventEmitter()
  ;(window as any).eventEmitter = eventEmitter
  return eventEmitter
}
