import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('./home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/user/login/index.vue'),
    meta: { layout: 'EmptyLayout' },
  },
  {
    path: '/reset-password/',
    name: 'reset-password',
    component: () => import('@/pages/user/restore-password/index.vue'),
    meta: { layout: 'EmptyLayout' },
  },
  {
    path: '/password_reset/',
    name: 'verify-password',
    component: () => import('@/pages/user/verify-password/index.vue'),
    meta: { layout: 'EmptyLayout' },
  },
  {
    path: '/applications/',
    name: 'applications',
    meta: {
      permission: ['VIEW_APPLICATIONS'],
    },
    component: () => import('@/pages/applications-and-dashboards/applications/index.vue'),
  },
  {
    path: '/application/:guid',
    name: 'application',
    meta: {
      permission: ['VIEW_APPLICATION'],
    },
    component: () => import('@/pages/applications-and-dashboards/application/index.vue'),
  },
  {
    path: '/dashboard/:guid',
    name: 'dashboard',
    meta: {
      permission: ['VIEW_DASHBOARD'],
    },
    component: () => import('@/pages/applications-and-dashboards/dashboard/index.vue'),
  },
  {
    path: '/dashboard-embed/:guid',
    name: 'dashboard-embed',
    meta: {
      permission: ['VIEW_DASHBOARD'],
    },
    component: () => import('@/pages/applications-and-dashboards/dashboard-embed/index.vue'),
  },
  {
    path: '/ml/',
    meta: {
      authority: ['S_MENU_PREDICTIVE_ANALYTICS'],
    },
    component: () => import('@/pages/machine-learning/index.vue'),
    children: [
      {
        path: 'experiments',
        name: 'ml-experiments',
        meta: {
          authority: ['S_SUBMENU_EXPERIMENTS'],
        },
        component: () => import('@/pages/machine-learning/experiments/index.vue'),
      },
      {
        path: 'experiments/:expGuid/runs',
        name: 'ml-experiment-runs',
        meta: {
          authority: ['S_SUBMENU_MODELS'],
          parentName: 'ml-experiments',
        },
        component: () => import('@/pages/machine-learning/runs/index.vue'),
      },
      {
        path: 'experiments/:expGuid/models',
        name: 'ml-experiment-models',
        meta: {
          authority: ['S_SUBMENU_MODELS'],
        },
        component: () => import('@/pages/machine-learning/models/index.vue'),
      },
      {
        path: 'models/:modelGuid/versions',
        name: 'ml-model-versions',
        meta: {
          authority: ['S_SUBMENU_MODELS'],
          parentName: 'ml-experiment-models',
        },
        component: () => import('@/pages/machine-learning/versions/index.vue'),
      },
      {
        path: 'models/:modelGuid/runs/:runGuid',
        name: 'ml-model-run',
        meta: {
          authority: ['S_SUBMENU_MODELS'],
          parentName: 'ml-experiments',
        },
        component: () => import('@/pages/machine-learning/run/index.vue'),
      },
      {
        path: 'models/versions/:versionGuid',
        name: 'ml-model-version',
        meta: {
          authority: ['S_SUBMENU_MODELS'],
          parentName: 'ml-experiment-models',
        },
        component: () => import('@/pages/machine-learning/version/index.vue'),
      },
      {
        path: 'constructor',
        name: 'ml-constructor',
        meta: {
          authority: ['S_SUBMENU_ML_CONSTRUCTOR'],
        },
        component: () => import('@/pages/machine-learning/constructor/index.vue'),
      },
      {
        path: 'settings',
        name: 'ml-settings',
        meta: {
          authority: ['S_SUBMENU_PARAMETERS_SETTINGS'],
        },
        component: () => import('@/pages/machine-learning/settings/index.vue'),
      },
    ],
  },
  {
    path: '/etl-constructor/',
    meta: {
      authority: ['S_MENU_CONSTRUCTOR_ETL'],
    },
    component: () => import('@/pages/etl-constructor/index.vue'),
    children: [
      {
        path: 'datasources',
        name: 'etl-datasources',
        meta: {
          authority: ['S_SUBMENU_DATA_SOURCE'],
        },
        component: () => import('@/pages/etl-constructor/datasources/index.vue'),
      },
      {
        path: ':type/storages',
        name: 'storages',
        meta: {
          authority: ['S_SUBMENU_ETL_STORAGE', 'S_SUBMENU_ANALYTICS'],
        },
        component: () => import('@/pages/etl-constructor/storages/index.vue'),
      },
      {
        path: 'tasks',
        name: 'etl-tasks',
        meta: {
          authority: ['S_SUBMENU_ETL_TASKS'],
        },
        component: () => import('@/pages/etl-constructor/tasks/index.vue'),
      },
      {
        path: 'task/:guid',
        name: 'etl-task',
        meta: {
          authority: ['S_SUBMENU_ETL_TASKS'],
        },
        component: () => import('@/pages/etl-constructor/task/index.vue'),
      },
      {
        path: 'datasource-structure/:guid',
        name: 'datasource-structure',
        meta: {
          authority: ['S_SUBMENU_DATA_SOURCE'],
        },
        component: () => import('@/pages/etl-constructor/datasource-structure/index.vue'),
      },
      {
        path: 'query-builder',
        name: 'query-builder',
        meta: {
          authority: ['S_SUBMENU_REQ_CONSTRUCTOR'],
        },
        component: () => import('@/pages/etl-constructor/query-builder/index.vue'),
      },
      {
        path: 'models',
        name: 'models',
        meta: {
          authority: ['S_SUBMENU_MODELS_CATALOG'],
        },
        component: () => import('@/pages/etl-constructor/data-models/index.vue'),
      },
      {
        path: 'task-instances',
        name: 'task-instances',
        meta: {
          authority: ['S_SUBMENU_ETL_TASKS_ITEMS'],
        },
        component: () => import('@/pages/etl-constructor/task-instances/index.vue'),
      },
    ],
  },
  {
    path: '/security/',
    meta: {
      authority: ['S_MENU_SECURITY'],
    },
    component: () => import('./security/index.vue'),
    children: [
      {
        path: 'users',
        name: 'users',
        meta: {
          authority: ['S_SUBMENU_USERS'],
        },
        component: () => import('./security/users/index.vue'),
      },
      {
        path: 'roles',
        name: 'roles',
        meta: {
          authority: ['S_SUBMENU_ROLES'],
        },
        component: () => import('./security/roles/index.vue'),
      },
      {
        path: 'groups',
        name: 'groups',
        meta: {
          authority: ['S_SUBMENU_ACCESS_GROUPS'],
        },
        component: () => import('./security/access-groups/index.vue'),
      },
      {
        path: 'group/:guid',
        name: 'settings',
        meta: {
          authority: ['S_SUBMENU_ACCESS_GROUPS'],
        },
        component: () => import('./security/access-groups-settings/index.vue'),
      },
      {
        path: 'users-activity',
        name: 'users-activity',
        meta: {
          authority: ['S_SUBMENU_USER_ACTIVITY'],
        },
        component: () => import('./security/users-activity/index.vue'),
      },
    ],
  },
  {
    path: '/administration/',
    meta: {
      authority: ['S_MENU_ADMINISTRATION'],
    },
    component: () => import('./administration/index.vue'),
    children: [
      {
        path: 'servers',
        name: 'servers',
        meta: {
          authority: ['S_SUBMENU_SERVERS'],
        },
        component: () => import('./administration/servers/index.vue'),
      },
      {
        path: 'domains',
        name: 'domains',
        meta: {
          authority: ['S_SUBMENU_REALMS'],
        },
        component: () => import('./administration/domains/index.vue'),
      },
      {
        path: 'system-datasources',
        name: 'system-datasources',
        meta: {
          authority: ['S_SUBMENU_SYSTEM_DATA_SOURCE'],
        },
        component: () => import('@/pages/administration/system-datasources/index.vue'),
      },
    ],
  },
  {
    path: '/404',
    name: 'not-found',
    component: () => import('./404.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
]
