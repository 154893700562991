import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { API_TIMEOUT } from '@/app/config/config'
import { ApiService, apiServiceUrls } from '@/shared/api/base-api/urls/serviceUrls'
import { refreshTokens } from '@/shared/api/base-api/decorators/refreshTokens'
import { EmitterEvent, useEventEmitter } from '@/shared/utils/event-emitter/useEventEmitter'

export class ApiError extends Error {
  serverData: any = null
  constructor(message: string, serverData: any = null) {
    super(message)
    this.name = 'ApiError'
    this.serverData = serverData
  }
}

const eventEmitter = useEventEmitter()

export default class BaseApi {
  constructor() {
    axios.defaults.timeout = API_TIMEOUT
  }

  public delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  static getQueryString(query: Record<string, string | number>): string {
    return Object.keys(query)
      .map(key => `${key}=${encodeURIComponent(query[key])}`)
      .join('&')
  }

  @refreshTokens
  protected get<T>(url: string, type: ApiService, options?: AxiosRequestConfig) {
    return axios
      .get<T>(apiServiceUrls[type] + url, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected delete<T>(url: string, type: ApiService, options?: AxiosRequestConfig) {
    return axios
      .delete<T>(apiServiceUrls[type] + url, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected deleteWithPayload<T1, T2>(url: string, payload: T1, type: ApiService, options?: AxiosRequestConfig) {
    return axios
      .delete<T2, AxiosResponse<T2>>(apiServiceUrls[type] + url, { ...options, data: payload as any })
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected post<T1, T2>(url: string, payload: T1, type: ApiService, options?: AxiosRequestConfig): Promise<T2> {
    return axios
      .post<T1, AxiosResponse<T2>>(apiServiceUrls[type] + url, payload, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected patch<T1, T2>(url: string, payload: T1, type: ApiService, options?: AxiosRequestConfig): Promise<T2> {
    return axios
      .patch<T1, AxiosResponse<T2>>(apiServiceUrls[type] + url, payload, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  protected put<T1, T2>(url: string, payload: T1, type: ApiService, options?: AxiosRequestConfig): Promise<T2> {
    return axios
      .put<T1, AxiosResponse<T2>>(apiServiceUrls[type] + url, payload, options)
      .then(extractData)
      .catch(processError)
  }

  @refreshTokens
  public uploadFile(data: any, guid: string) {
    return this.post('/files?owner=' + guid, data, ApiService.SERVICE_FILE_LOADER, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

function extractData<T = any>(response: AxiosResponse<T>) {
  const { data, status } = response
  if (status !== 200) {
    const errorMessage = (data as any).errorMessage || (data as any).error_message
    if (errorMessage) throw new ApiError(errorMessage, data)
  }
  return data
}

function processError(err: AxiosError): never {
  const data: any = err.response?.data
  const status = err.response?.status

  if (status === 401) {
    eventEmitter.emit(EmitterEvent.JWT_EXPIRED_OR_INVALID)
  }

  let errorMessage = data?.error?.error || data?.error || data?.errorMessage || data?.error_message || err.message
  if (Array.isArray(errorMessage)) errorMessage = JSON.stringify(errorMessage[0])
  throw new ApiError(errorMessage, err.response?.data || null)
}
