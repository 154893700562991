export enum UserRole {
  'ROLE_MASTER_ADMIN' = 'ROLE_MASTER_ADMIN',
  'ROLE_REALM_ADMIN' = 'ROLE_REALM_ADMIN',
  'ROLE_DATA_ENGINEER' = 'ROLE_DATA_ENGINEER',
  'ROLE_APPLICATION_DESIGNER' = 'ROLE_APPLICATION_DESIGNER',
  'ROLE_USER' = 'ROLE_USER',
  'ROLE_TECHNICIAN' = 'ROLE_TECHNICIAN',
  'ROLE_ML_ENGINEER' = 'ROLE_ML_ENGINEER',
}

export type UserAuthority =
  | 'S_WIDGET_TEMPLATES_GET_ALL'
  | 'S_SUBMENU_USER_ACTIVITY'
  | 'S_USER_CREATE_ACCESS_GROUP'
  | 'S_DATASOURCES_DATASOURCES_UPDATE_SCHEMA'
  | 'S_DATASOURCES_DATASOURCES_GET_ETL_FOR_TREE'
  | 'S_SUBMENU_DATA_SOURCE'
  | 'S_WIDGETS_GET_BY_DASHBOARD'
  | 'S_SUBMENU_ETL_TASKS_ITEMS'
  | 'S_ETL_MIDDLEWARE_DATA_ENGINEER'
  | 'S_USER_BLOCK_USER_BY_REALM'
  | 'S_USER_GET_ROLE_GROUP'
  | 'S_WIDGETS_GET_DATA'
  | 'S_USER_GET_ACCESS_GROUP'
  | 'S_WIDGETS_CREATE'
  | 'S_USER_CREATE_ETL_SCHEMA'
  | 'S_WIDGETS_UPDATE_DASHBOARD_WIDGETS'
  | 'S_DATASOURCES_FIELDS_ALL_BY_OBJECT'
  | 'S_DATASOURCES_DATASOURCES_PREVIEW_DATA'
  | 'S_ML_DELETE_EXPERIMENT'
  | 'S_USER_GET_REALM'
  | 'S_WIDGETS_UPDATE'
  | 'S_NS_GET_NOTIFICATION_BY_USER'
  | 'S_DATASOURCES_OBJECTS_RESTORE'
  | 'S_SUBMENU_ETL_SCHEMES'
  | 'S_DATASOURCES_DATASOURCES_TYPES'
  | 'S_SUBMENU_MODELS_CATALOG'
  | 'S_USER_ADD_USER_ACCESS_GROUP'
  | 'S_USER_GET_ETL_SCHEMA'
  | 'S_NS_MARK_AS_SEEN_BY_GUID'
  | 'S_DATASOURCES_OBJECTS_DELETE'
  | 'S_SUBMENU_REQ_CONSTRUCTOR'
  | 'S_DATASOURCES_DATASOURCES_TEST_CONNECTION'
  | 'S_DATASOURCES_DATASOURCES_WAREHOUSE_TYPES'
  | 'S_NS_DELETE_BY_USER'
  | 'S_DATASOURCES_OBJECTS_GET_FOR_TREE'
  | 'S_DATASOURCES_FIELDS_DELETE'
  | 'S_DATASOURCES_DATASOURCES_AUTHORIZATION_MODES'
  | 'S_SUBMENU_ANALYTICS'
  | 'S_DATASOURCES_FIELDS_RESTORE'
  | 'S_DATASOURCES_DATASOURCES_DEFAULT_SETTINGS'
  | 'S_SUBMENU_ROLES'
  | 'S_DATASOURCES_OBJECTS_GET_BY_GUID'
  | 'S_DATASOURCES_DATASOURCES_SCHEMA'
  | 'S_DATASOURCES_OBJECTS_ALL'
  | 'S_USER_GET_ETL_SCHEMA_NAMES'
  | 'S_SUBMENU_SYSTEM_DATA_SOURCE'
  | 'S_USER_UPDATE_ACCESS_GROUP'
  | 'S_DATASOURCES_DATASOURCES_DELETE'
  | 'S_SUBMENU_ETL_STORAGE'
  | 'S_DATASOURCES_DATASOURCES_GET_TABLES'
  | 'S_SUBMENU_ETL_TASKS'
  | 'S_DATASOURCES_DATASOURCES_UPDATE'
  | 'S_ML_UPDATE_EXPERIMENT'
  | 'S_SUBMENU_USER_ACTIONS'
  | 'S_DATASOURCES_DATASOURCES_GET_BY_GUID'
  | 'S_ML_GET_EXPERIMENT'
  | 'S_SUBMENU_USERS'
  | 'S_USER_GET_SYSTEM_DATASOURCE'
  | 'S_ML_CREATE_EXPERIMENT'
  | 'S_USER_UPDATE_ETL_SCHEMA'
  | 'S_MENU_SECURITY'
  | 'S_USER_GET_REALM_USER_MAPPING_BY_REALM'
  | 'S_DATASOURCES_DATASOURCES_CREATE'
  | 'S_MENU_ADMINISTRATION'
  | 'S_DATASOURCES_OBJECTS_ALL_BY_DATASOURCE'
  | 'S_DATASOURCES_DATASOURCES_RESTORE'
  | 'S_DATASOURCES_FIELDS_GET_FOR_TREE'
  | 'S_DATASOURCES_DATASOURCES_ALL'
  | 'S_USER_DELETE_ACCESS_GROUP'
  | 'S_DATASOURCES_DATASOURCES_UPDATE_WHOLE_SCHEMA'
  | 'S_MENU_APPLICATIONS'
  | 'S_SUBMENU_ACCESS_GROUPS'
  | 'S_DATASOURCES_DATASOURCES_GET_ANALYTICAL_FOR_TREE'
  | 'S_USER_MANAGE_USERS_OF_ROLE_GROUP'
  | 'S_MENU_CONSTRUCTOR_ETL'
  | 'S_USER_CHANGE_OWN_PASSWORD'
  | 'S_USER_DELETE_ETL_SCHEMA'
  | 'CREATE_ETL_SCHEMA'
  | 'UPDATE_ETL_SCHEMA'
  | 'DELETE_ETL_SCHEMA'
  | 'GET_ETL_SCHEMA'
  | 'GET_ETL_SCHEMA_NAMES'
  | 'LOGIN_AS_ANOTHER'
  | 'FORCED_LOGOUT'
  | 'CLEAN_UP_CASH'
  | 'CLEAN_UP_DATABASE'
  | 'MENU_ADMINISTRATION'
  | 'MENU_CONSTRUCTOR'
  | 'MENU_DATA_MODELS'
  | 'MENU_VISUALISATION_SETTINGS'
  | 'MENU_CREATE_WIDGET'
  | 'MENU_APPLICATIONS'
  | 'MENU_WIDGETS'
  | 'GET_NOTIFICATION_BY_USER'
  | 'MARK_AS_SEEN_BY_GUID'
  | 'DELETE_BY_USER'
  | 'CREATE_DATA_SERVER'
  | 'UPDATE_DATA_SERVER'
  | 'DELETE_DATA_SERVER'
  | 'GET_DB_DATA_SERVER'
  | 'GET_DATA_SERVER'
  | 'CREATE_LDAP_AUTH_MODE'
  | 'DELETE_LDAP_AUTH_MODE'
  | 'UPDATE_LDAP_AUTH_MODE'
  | 'GET_LDAP_AUTH_MODE'
  | 'CREATE_SQL_FUNCTION'
  | 'DELETE_SQL_FUNCTION'
  | 'UPDATE_SQL_FUNCTION'
  | 'GET_SQL_FUNCTION'
  | 'CREATE_SQL_FUNCTION_GROUP'
  | 'DELETE_SQL_FUNCTION_GROUP'
  | 'UPDATE_SQL_FUNCTION_GROUP'
  | 'GET_SQL_FUNCTION_GROUP'
  | 'CREATE_SQL_FUNCTION_CONNECTOR_TYPE'
  | 'DELETE_SQL_FUNCTION_CONNECTOR_TYPE'
  | 'UPDATE_SQL_FUNCTION_CONNECTOR_TYPE'
  | 'GET_SQL_FUNCTION_CONNECTOR_TYPE'
  | 'CREATE_REALM'
  | 'UPDATE_REALM'
  | 'DELETE_REALM'
  | 'GET_REALM'
  | 'CREATE_REALM_DATABASE'
  | 'UPDATE_REALM_DATABASE'
  | 'DELETE_REALM_DATABASE'
  | 'GET_REALM_DATABASE_GLOBAL'
  | 'GET_REALM_DATABASE_BY_REALM'
  | 'MANAGE_REALM_ADMINISTRATOR'
  | 'CREATE_SYSTEM_DATASOURCE'
  | 'UPDATE_SYSTEM_DATASOURCE'
  | 'DELETE_SYSTEM_DATASOURCE'
  | 'GET_SYSTEM_DATASOURCE_GLOBAL'
  | 'GET_SYSTEM_DATASOURCE'
  | 'MENU_SECURITY'
  | 'CREATE_USER'
  | 'UPDATE_USER'
  | 'CREATE_REALM_USER_MAPPING'
  | 'DELETE_REALM_USER_MAPPING'
  | 'BLOCK_USER_BY_REALM'
  | 'BLOCK_USER_GLOBAL'
  | 'DELETE_USER'
  | 'GET_REALM_USER_MAPPING_BY_REALM'
  | 'GET_REALM_USER_MAPPING_BY_USER'
  | 'GET_USER'
  | 'GET_USER_GLOBAL'
  | 'GET_AUTHORITY'
  | 'CHANGE_PASSWORD'
  | 'CHANGE_OWN_PASSWORD'
  | 'CREATE_ACCESS_GROUP'
  | 'UPDATE_ACCESS_GROUP'
  | 'DELETE_ACCESS_GROUP'
  | 'GET_ACCESS_GROUP'
  | 'ADD_USER_ACCESS_GROUP'
  | 'CREATE_USER_GROUP'
  | 'UPDATE_USER_GROUP'
  | 'DELETE_USER_GROUP'
  | 'GET_USER_GROUP'
  | 'MANAGE_USERS_OF_USER_GROUP'
  | 'CREATE_ROLE_GROUP'
  | 'UPDATE_ROLE_GROUP'
  | 'DELETE_ROLE_GROUP'
  | 'GET_ROLE_GROUP'
  | 'MANAGE_USERS_OF_ROLE_GROUP'
  | 'MANAGE_USER_ROLES_OF_ROLE_GROUP'
  | 'GET_USER_BY_REALM'
  | 'GET_REALM_BY_USER'
  | 'CREATE_USER_ROLE'
  | 'DELETE_USER_ROLE'
  | 'UPDATE_USER_ROLE'
  | 'GET_USER_ROLE'
  | 'UPDATE_MASTER_ROLE'
  | 'FORCE_AUTHORITY_UPDATE'
  | 'GET_USER_GROUP_TREE'
  | 'MANAGE_USER_GROUP_CHILDREN'
  | 'MANAGE_ROLE_GROUP_CHILDREN'
  | 'MANAGE_USER_ROLE_CHILDREN'
  | 'S_FILTERS_GLOBAL_FILTERS_CREATE'
  | 'S_FILTERS_GLOBAL_FILTERS_UPDATE'
  | 'S_FILTERS_GLOBAL_FILTERS_DELETE'
  | 'S_FILTERS_GLOBAL_GET_FOR_APPLICATION'
  | 'S_FILTERS_GLOBAL_GET_HIDDEN_FOR_APPLICATION'
  | 'S_FILTERS_USER_GLOBAL_FILTERS_UPDATE'
  | 'S_FILTERS_USER_GLOBAL_FILTERS_ENABLE'
  | 'S_FILTERS_USER_GLOBAL_FILTERS_DISABLE'
  | 'S_FILTERS_CROSS_FILTERS_CREATE_STEP'
  | 'S_FILTERS_CROSS_FILTERS_GET_STEPS'
  | 'S_FILTERS_CROSS_FILTERS_CLEAR_STEPS'
  | 'S_FILTERS_CROSS_FILTERS_DELETE_OLDER_STEPS'
  | 'S_APPLICATIONS_DASHBOARDS_CREATE_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_UPDATE_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_DELETE_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_ALL_APPLICATIONS'
  | 'S_APPLICATIONS_DASHBOARDS_COPY_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_GET_ONE_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_APPLICATION_GET_DASHBOARDS'
  | 'S_APPLICATIONS_DASHBOARDS_CREATE_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_UPDATE_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_DELETE_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_COPY_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_GET_ONE_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_UPDATE_DASHBOARD_PREVIEW'
  | 'S_APPLICATIONS_DASHBOARDS_PUBLISH_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_UNPUBLISH_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_APPLICATIONS_GET_UNPUBLISHED'
  | 'S_DATA_MODELS_MODELS_GET_ALL'
  | 'S_DATA_MODELS_MODELS_CREATE'
  | 'S_DATA_MODELS_MODELS_DELETE'
  | 'S_DATA_MODELS_MODELS_RESTORE'
  | 'S_DATA_MODELS_MODELS_GET_ONE'
  | 'S_DATA_MODELS_MODELS_UPDATE'
  | 'DATASOURCES_ALL'
  | 'DATASOURCES_CREATE'
  | 'DATASOURCES_GET_BY_GUID'
  | 'DATASOURCES_GET_ETL_FOR_TREE'
  | 'DATASOURCES_GET_ANALYTICAL_FOR_TREE'
  | 'FIELDS_GET_FOR_TREE'
  | 'ETL_DATASOURCES_ALL'
  | 'ETL_DATASOURCES_CREATE'
  | 'ETL_DATASOURCES_TREE'
  | 'DWH_DATASOURCES_ALL'
  | 'DWH_DATASOURCES_CREATE'
  | 'DWH_DATASOURCES_TREE'
  | 'ESB_DATASOURCES_ALL'
  | 'ESB_DATASOURCES_CREATE'
  | 'ESB_DATASOURCES_TREE'
  | 'DATASOURCES_GET'
  | 'DATASOURCES_GET_TABLES'
  | 'DATASOURCES_DELETE'
  | 'DATASOURCES_RESTORE'
  | 'DATASOURCES_UPDATE'
  | 'DATASOURCES_AUTHORIZATION_MODES'
  | 'DATASOURCES_DEFAULT_SETTINGS'
  | 'DATASOURCES_TYPES'
  | 'DATASOURCES_WAREHOUSE_TYPES'
  | 'DATASOURCES_SCHEMA'
  | 'DATASOURCES_UPDATE_SCHEMA'
  | 'DATASOURCES_UPDATE_WHOLE_SCHEMA'
  | 'DATASOURCES_TEST_CONNECTION'
  | 'DATASOURCES_PREVIEW_DATA'
  | 'OBJECTS_GET'
  | 'OBJECTS_ALL'
  | 'OBJECTS_ALL_BY_DATASOURCE'
  | 'OBJECTS_DELETE'
  | 'OBJECTS_RESTORE'
  | 'OBJECTS_PREVIEW_DATA'
  | 'OBJECTS_TREE'
  | 'OBJECTS_UPDATE'
  | 'OBJECTS_PUBLISH'
  | 'OBJECTS_UNPUBLISH'
  | 'FIELDS_GET'
  | 'FIELDS_GET_DATA'
  | 'FIELDS_ALL_BY_OBJECT'
  | 'FIELDS_DELETE'
  | 'FIELDS_RESTORE'
  | 'FIELDS_TREE'
  | 'FIELDS_UPDATE'
  | 'EXTERNAL_DATASOURCES_CREATE'
  | 'EXTERNAL_DATASOURCES_GET'
  | 'EXTERNAL_DATASOURCES_TABLES'
  | 'EXTERNAL_DATASOURCES_DELETE'
  | 'EXTERNAL_DATASOURCES_RESTORE'
  | 'EXTERNAL_DATASOURCES_UPDATE'
  | 'EXTERNAL_DATASOURCES_AUTHORIZATION_MODES'
  | 'EXTERNAL_DATASOURCES_TYPES'
  | 'EXTERNAL_DATASOURCES_DEFAULT_SETTINGS'
  | 'EXTERNAL_DATASOURCES_SCHEMA'
  | 'EXTERNAL_DATASOURCES_UPDATE_SCHEMA'
  | 'EXTERNAL_DATASOURCES_UPDATE_WHOLE_SCHEMA'
  | 'EXTERNAL_DATASOURCES_TEST_CONNECTION'
  | 'EXTERNAL_DATASOURCES_PREVIEW_DATA'
  | 'EXTERNAL_OBJECTS_GET'
  | 'EXTERNAL_OBJECTS_ALL'
  | 'EXTERNAL_OBJECTS_ALL_BY_DATASOURCE'
  | 'EXTERNAL_OBJECTS_DELETE'
  | 'EXTERNAL_OBJECTS_RESTORE'
  | 'EXTERNAL_OBJECTS_PREVIEW_DATA'
  | 'EXTERNAL_OBJECTS_TREE'
  | 'EXTERNAL_OBJECTS_UPDATE'
  | 'EXTERNAL_OBJECTS_PUBLISH'
  | 'EXTERNAL_OBJECTS_UNPUBLISH'
  | 'EXTERNAL_FIELDS_GET'
  | 'EXTERNAL_FIELDS_GET_DATA'
  | 'EXTERNAL_FIELDS_ALL_BY_OBJECT'
  | 'EXTERNAL_FIELDS_DELETE'
  | 'EXTERNAL_FIELDS_RESTORE'
  | 'EXTERNAL_FIELDS_TREE'
  | 'EXTERNAL_FIELDS_UPDATE'
  | 'S_DATASOURCES_SERVERS_GET_ALL'
  | 'S_DATASOURCES_SERVERS_CREATE'
  | 'S_DATASOURCES_SERVERS_DELETE'
  | 'S_DATASOURCES_SERVERS_UPDATE'
  | 'S_DATASOURCES_SERVERS_GET_ONE'
  | 'S_DATASOURCES_SERVERS_GET_DB_NAMES'
  | 'S_DATASOURCES_SERVERS_TEST_CONNECTION'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_GET_BY_REALM'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_GET_BY_REALM_LITE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_CREATE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_PREVIEW'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_UPDATE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_GET_ONE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_DELETE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_TEST_CONNECTION'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_LOAD_OBJECTS'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_ACTUALIZE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_ACTUALIZE_OBJECTS'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_OBJECTS'
  | 'S_DATASOURCES_OBJECTS_GET_FIELDS'
  | 'S_DATASOURCES_OBJECTS_GET_FIELDS_LITE'
  | 'S_DATASOURCES_OBJECTS_UPDATE_PRESENTATION_NAME'
  | 'S_DATASOURCES_OBJECTS_PUBLISH'
  | 'S_DATASOURCES_OBJECTS_UNPUBLISH'
  | 'S_DATASOURCES_OBJECTS_GET_ONE'
  | 'S_DATASOURCES_OBJECTS_UPDATE'
  | 'S_DATASOURCES_OBJECTS_PREVIEW_DATA'
  | 'S_DATASOURCES_SCHEMAS_CREATE'
  | 'S_DATASOURCES_SCHEMAS_DELETE'
  | 'S_DATASOURCES_SCHEMAS_ONE'
  | 'S_DATASOURCES_SCHEMAS_UPDATE'
  | 'S_DATASOURCES_SCHEMAS_GET_OBJECTS'
  | 'S_DATASOURCES_SCHEMAS_GET_OBJECTS_LITE'
  | 'S_DATASOURCES_SCHEMAS_ACTUALIZE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_CREATE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_SOURCE_SCHEMAS_NAMES'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_TEST_CONNECTION'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_SOURCE_OBJECTS_NAMES'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_UPDATE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_DELETE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_BY_REALM_LITE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_ONE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_BATCH_UPDATE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_SCHEMAS'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_SCHEMAS_LITE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_ACTUALIZE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_ACTUALIZE_OBJECTS'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_PREVIEW'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_BY_REALM'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_OBJECTS'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_OBJECTS_LITE'
  | 'S_DATASOURCES_FIELDS_GET_DATA'
  | 'S_DATASOURCES_FIELDS_UPDATE_PRESENTATION_NAME'
  | 'S_DATASOURCES_FIELDS_GET_BY_GUID'
  | 'S_DATASOURCES_FIELDS_UPDATE'
  | 'S_DATASOURCES_COMMON_TEST_CONNECTION'
  | 'S_DATASOURCES_COMMON_DB_TEST_CONNECTION'
  | 'S_DATASOURCES_COMMON_GET_DEFAULT_SETTINGS'
  | 'S_DATASOURCES_TYPES_AUTH'
  | 'S_DATASOURCES_TYPES_DATA_PROVIDERS'
  | 'S_DATASOURCES_TYPES_DATASOURCES'
  | 'S_DATASOURCES_TYPES_DEFINE_AS'
  | 'S_DATASOURCES_TYPES_STORAGES'
  | 'S_DATASOURCES_TYPES_TABLES'
  | 'OBJECTS_GET_FOR_TREE'
  | 'S_WIDGETS_WIDGETS_CREATE'
  | 'S_WIDGETS_WIDGETS_UPDATE'
  | 'S_WIDGETS_WIDGETS_ONE'
  | 'S_WIDGETS_WIDGETS_DATA'
  | 'S_WIDGETS_WIDGETS_QUERY_DATA'
  | 'S_WIDGETS_WIDGETS_STEP_DATA'
  | 'S_WIDGETS_WIDGETS_GET_BY_DASHBOARD'
  | 'S_WIDGETS_WIDGETS_GET_BY_DASHBOARD_LITE'
  | 'S_WIDGETS_WIDGETS_FILTER'
  | 'S_WIDGETS_WIDGETS_UPDATE_POSITIONS'
  | 'S_WIDGETS_ACTIVATION_RULES_CREATE'
  | 'S_WIDGETS_ACTIVATION_RULES_UPDATE'
  | 'S_WIDGETS_ACTIVATION_RULES_DELETE'
  | 'S_WIDGETS_ACTIVATION_RULES_GET_BY_DASHBOARD'
  | 'GET_JSON'
  | 'GET_SQL'
  | 'VALIDATE_SQL'
  | 'CREATE_EXPERIMENT'
  | 'GET_EXPERIMENT'
  | 'DELETE_EXPERIMENT'
  | 'UPDATE_EXPERIMENT'
  | 'CREATE_MODEL'
  | 'UPDATE_MODEL'
  | 'GET_MODEL'
  | 'DELETE_MODEL'
  | 'CREATE_STEP'
  | 'UPDATE_STEP'
  | 'GET_STEP'
  | 'DELETE_STEP'
  | 'CREATE_LINK'
  | 'DELETE_LINK'
  | 'GET_RUN'
  | 'RUN_STEP'
  | 'S_USER_VIEW_ACCESS_GROUP'
  | 'S_USER_EDIT_ACCESS_GROUP'
  | 'S_ETL_MIDDLEWARE_EDIT_ACCESS_GROUP'
  | 'S_ETL_MIDDLEWARE_VIEW_ACCESS_GROUP'
  | 'S_APPLICATIONS_DASHBOARDS_EDIT_ACCESS_GROUP'
  | 'S_APPLICATIONS_DASHBOARDS_VIEW_ACCESS_GROUP'
