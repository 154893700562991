import BaseApi from '@/shared/api/base-api/BaseApi'
import { sha256 } from 'js-sha256'
import {
  Api3_AuthTypesDto,
  Api3_ExtendedJwtResponseDto,
  Api3_JwtRequestDto,
  Api3_UserActivityDTO,
} from '@/shared/api/types/api-v3-service-auth'
import {
  Api3_ChangePasswordDto,
  Api3_RestorePasswordDto,
  Api3_VerifyPasswordDto,
} from '@/shared/api/types/api-v3-service-user'
import { ApiService } from '@/shared/api/base-api/urls/serviceUrls'

class AuthApi extends BaseApi {
  private authTypes!: Api3_AuthTypesDto

  public fetchAuthTypes(): Promise<Api3_AuthTypesDto> {
    if (this.authTypes) return Promise.resolve(this.authTypes)
    return this.get<Api3_AuthTypesDto>(`/auth/get_auth_types`, ApiService.SERVICE_AUTH).then(authTypes => {
      this.authTypes = authTypes
      return authTypes
    })
  }

  public loginUser(username: string, password: string): Promise<Api3_ExtendedJwtResponseDto> {
    return this.fetchAuthTypes().then(() =>
      this.post<Api3_JwtRequestDto, Api3_ExtendedJwtResponseDto>(
        `/auth/login`,
        {
          username,
          password: (this.authTypes as any).allow_password_hashing ? sha256(password) : password,
        },
        ApiService.SERVICE_AUTH,
      ),
    )
  }

  public logoutUser(): Promise<void> {
    return this.get<void>(`/auth/logout`, ApiService.SERVICE_AUTH)
  }

  public changePassword(old_password: string, new_password: string): Promise<void> {
    return this.put<Api3_ChangePasswordDto, void>(
      `/passwords/change`,
      {
        old_password,
        new_password,
      },
      ApiService.SERVICE_USER,
    )
  }

  public restorePassword(alias: string): Promise<void> {
    return this.post<Api3_RestorePasswordDto, void>(`/passwords/restore`, { alias }, ApiService.SERVICE_USER)
  }

  public verifyPassword(user_guid: string, new_password: string, verification_code: string): Promise<void> {
    return this.put<Api3_VerifyPasswordDto, void>(
      `/passwords/verify`,
      { user_guid, new_password, verification_code },
      ApiService.SERVICE_USER,
    )
  }

  public fetchAllUsersActivity(): Promise<Api3_UserActivityDTO[]> {
    return this.get<Api3_UserActivityDTO[]>('/user-activity', ApiService.SERVICE_AUTH)
  }

  public enterToDomain(id: string) {
    this.post(`/api/realms/${id}/enter`, {}, ApiService.SERVICE_USER)
  }

  public exitFromDomain(id: string) {
    this.post(`/api/realms/${id}/exit`, {}, ApiService.SERVICE_USER)
  }
}

let api: AuthApi
export default function useAuthApi() {
  if (!api) api = new AuthApi()
  return api
}
