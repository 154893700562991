<template>
  <router-view />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { init } from '@/app/init'

onMounted(init)
</script>
