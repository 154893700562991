import languages from '@/app/providers/translate/languages'

const defaultLang = 'ru'

const localeLoader: Record<string, () => any> = {
  en: () => import('./locales/dictionary-en'),
  ru: () => import('./locales/dictionary-ru'),
}

export const translate = (afterCallback: any) => async (app: any) => {
  let lang = localStorage.getItem('lang') || defaultLang
  if (!languages.map(x => x.label).includes(lang)) lang = defaultLang
  // @ts-ignore
  window.$translate = (await localeLoader[lang]()).words
  // @ts-ignore
  app.config.globalProperties.$translate = window.$translate
  //
  afterCallback()
}
