const API_URL = (process.env as any).VUE_APP_API_URL.replace(/\/$/, '')

export enum ApiService {
  SERVICE_APPLICATIONS = 'SERVICE_APPLICATIONS',
  SERVICE_AUTH = 'SERVICE_AUTH',
  SERVICE_DATASOURCES = 'SERVICE_DATASOURCES',
  SERVICE_DATA_MODELS = 'SERVICE_DATA_MODELS',
  SERVICE_ETL_MIDDLEWARE = 'SERVICE_ETL_MIDDLEWARE',
  SERVICE_FILE_LOADER = 'SERVICE_FILE_LOADER',
  SERVICE_FILTERS = 'SERVICE_FILTERS',
  SERVICE_MENU = 'SERVICE_MENU',
  SERVICE_ML_MIDDLEWARE = 'SERVICE_ML_MIDDLEWARE',
  SERVICE_NOTIFICATION_STORE = 'SERVICE_NOTIFICATION_STORE',
  SERVICE_QUERYBUILDER = 'SERVICE_QUERYBUILDER',
  SERVICE_IMAGES = 'SERVICE_IMAGES',
  SERVICE_USER = 'SERVICE_USER',
  SERVICE_WIDGETS = 'SERVICE_WIDGETS',
}

export const apiServiceUrls: Record<ApiService, string> = {
  SERVICE_APPLICATIONS: `${API_URL}/service-applications-dashboards`,
  SERVICE_AUTH: `${API_URL}/service-auth`,
  SERVICE_DATASOURCES: `${API_URL}/service-datasources`,
  SERVICE_DATA_MODELS: `${API_URL}/service-data-models`,
  SERVICE_ETL_MIDDLEWARE: `${API_URL}/service-etl-middleware`,
  SERVICE_FILE_LOADER: `${API_URL}/service-file-loader`,
  SERVICE_FILTERS: `${API_URL}/service-filters`,
  SERVICE_IMAGES: `${API_URL}/service-images`,
  SERVICE_MENU: `${API_URL}/service-menu`,
  SERVICE_ML_MIDDLEWARE: `${API_URL}/service-ml-middleware`,
  SERVICE_NOTIFICATION_STORE: `${API_URL}/service-notification-store`,
  SERVICE_QUERYBUILDER: `${API_URL}/service-querybuilder`,
  SERVICE_USER: `${API_URL}/service-user`,
  SERVICE_WIDGETS: `${API_URL}/service-widgets`,
}
